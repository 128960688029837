import { useLocation } from "@reach/router"
import DOMPurify from "dompurify"
import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import TrackVisibility from "react-on-screen"
import Arrow from "../../assets/images/c-arrow.svg"
import Dice from "../../assets/images/dice.svg"
import Doodle from "../../assets/images/mental-health-doodle.svg"
import Buttons from "../../components/common/button"
import { setHubspotutkCookie } from "../../components/common/FormBuilder/Form-Function"
import Frame from "../../components/common/frame"
import Links from "../../components/common/links"
import YoutubePlayer from "../../components/common/Youtube-player"
import Slide from "../../components/utility/slide_content"
import {
  checkInvalid,
  checkValidEmail,
} from "../../components/utility/validation"
import "../../styles/pages/mental-health.scss"

function MentalHealthCopy() {
  const [recipientName, setRecipientName] = useState("")

  const [recipientEmail, setRecipientEmail] = useState("")
  const [senderEmail, setSenderEmail] = useState("")

  const [name, setName] = useState("")
  const [shake, setShake] = useState(false)

  const [senderEmailError, setSenderEmailError] = useState(false)
  const [recipientEmailError, setRecipientEmailError] = useState("")

  const [nameError, setNameError] = useState(false)
  const [rNameError, setRNameError] = useState(false)

  const [thankyou, setThankyou] = useState(false)

  const currentLocation = useLocation()

  const [submitPress, setSubmitPress] = useState(false)

  const resetError = () => {
    setShake(false)
    setRecipientEmailError("")
    setSenderEmailError("")
    setNameError(false)
    setRNameError(false)
    setSubmitPress(false)
  }

  useEffect(() => {
    if (submitPress) {
      if (name === "" || checkInvalid(name)) {
        setNameError(true)
        setShake(true)
        setTimeout(resetError, 4000)
      } else {
        setNameError(false)
      }

      if (recipientName === "" || checkInvalid(recipientName)) {
        setRNameError(true)
        setShake(true)
        setTimeout(resetError, 4000)
      } else {
        setRNameError(false)
      }

      if (recipientEmail === "" || !checkValidEmail(recipientEmail)) {
        setRecipientEmailError("1px solid #ff0055")
        setShake(true)
        setTimeout(resetError, 4000)
      } else {
        setRecipientEmailError("1px solid #190429")
      }

      if (senderEmail === "" || !checkValidEmail(senderEmail)) {
        setSenderEmailError("1px solid #ff0055")
        setShake(true)
        setTimeout(resetError, 4000)
      } else {
        setSenderEmailError("1px solid #190429")
      }
    }
  }, [name, recipientName, recipientEmail, submitPress, senderEmail])

  const handleSubmit = evt => {
    evt.preventDefault()
    setSubmitPress(true)

    if (
      recipientEmail !== "" &&
      checkValidEmail(recipientEmail) &&
      senderEmail !== "" &&
      checkValidEmail(senderEmail) &&
      name !== "" &&
      !checkInvalid(name) &&
      recipientName !== "" &&
      !checkInvalid(recipientName)
    ) {
      var xhr = new XMLHttpRequest()
      var url = process.env.HUBSPOT_MENTAL_HEALTH_ENDPOINT_2024
      var data = {
        submittedAt: Date.now(),
        fields: [
          {
            name: "email",
            value: DOMPurify.sanitize(recipientEmail),
          },
          {
            name: "sender_s_email",
            value: DOMPurify.sanitize(senderEmail),
          },
          {
            name: "firstname",
            value: DOMPurify.sanitize(recipientName),
          },
          {
            name: "sender_s_name",
            value: DOMPurify.sanitize(name),
          },
        ],
        context: {
          hutk: setHubspotutkCookie("hubspotutk"),
          pageUri:
            "https://superops.com/managed-service-provider/mental-health",
          pageName: "MSP Mental Health",
        },
      }

      var final_data = JSON.stringify(data)

      xhr.open("POST", url)

      xhr.setRequestHeader("Content-Type", "application/json")

      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // setResponse(true)
          // window.location.href = `/thank-you?redirect=${currentLocation.pathname}`
          setSenderEmail("")
          setRecipientEmail("")
          setRecipientName("")
          setName("")
          setShake(false)
          setRecipientEmailError("")
          setSenderEmailError("")
          setNameError(false)
          setRNameError(false)
          setSubmitPress(false)
          setThankyou(true)
        } else if (
          xhr.readyState === 4 &&
          (xhr.status === 400 || xhr.status === 403 || xhr.status === 404)
        ) {
          setRecipientEmailError("1px solid #ff0055")
          setSenderEmailError("1px solid #ff0055")
          setShake(true)
          setTimeout(resetError, 4000)
        }
      }
      xhr.send(final_data)
    }
  }

  const [h1, setH1] = useState([])

  useEffect(() => {
    var a = document.querySelector("#heading > h1 ")
    var b = a.innerHTML
    var ar = b.split(" ")
    setH1(ar)
  }, [])

  var delay = 0

  // useEffect(() => {
  //   let ctw= document.getElementById("play")
  //   console.log(ctw)
  // },[])

  const click = () => {
    let ctw = document.getElementById("play")
    ctw.click()
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query MSPMentalhealthCopy {
            SuperOps {
              pages(where: { title: "MSP Mental Health New" }) {
                title
                linkTitle
                pageImages {
                  id
                  url
                }
                content {
                  html
                }
                pageBlock {
                  content {
                    html
                  }
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
              }
              links(where: { section: "Social" }) {
                section
                linkName
                slug
                icon {
                  url
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            {data.SuperOps.pages.map(page => {
              const { content, pageBlock, seo, pageImages, linkTitle } = page

              return (
                <Frame seo={seo} IsFooterVisble="No">
                  <div className="msp-mental-health">
                    <div className="hero">
                      <div className="d-none" id="heading">
                        {parse(pageBlock[0].content.html)}
                      </div>
                      <div className="heading">
                        <h1>
                          {h1.map((el, i) => {
                            return (
                              <div className="mr-1">
                                {el.split("").map((w1, w) => {
                                  return (
                                    <span
                                      style={{
                                        animationDelay: `${delay * 0.03 +
                                          0.5}s`,
                                      }}
                                    >
                                      {w1}
                                      <div className="d-none">
                                        {(delay += 1)}{" "}
                                      </div>
                                    </span>
                                  )
                                })}
                              </div>
                            )
                          })}
                        </h1>
                      </div>
                      <Slide style={{ "--sal-delay": "1.8s" }}>
                        <div className="sub-text">
                          {parse(pageBlock[5].content.html)}
                        </div>
                      </Slide>
                      <Slide style={{ "--sal-delay": "0.2s" }}>
                        <div className="d-flex justify-content-center">
                          <img
                            className="doodle"
                            src={Doodle}
                            alt="img"
                            onClick={click}
                          />
                        </div>
                        <div className="vdo">
                          <YoutubePlayer videoURL={linkTitle[0]} />
                        </div>
                      </Slide>
                    </div>
                    <Slide style={{ "--sal-delay": "0.2s" }}>
                      <div className="p18 ctw">
                        <div className="dsp-md">
                          <TrackVisibility once offset={-200}>
                            {({ isVisible }) => (
                              <>
                                <img
                                  src={Arrow}
                                  alt="arrow"
                                  className={`arrow ${
                                    isVisible ? "shake" : null
                                  }`}
                                />
                                <p
                                  onClick={click}
                                  className={`anim-p ${
                                    isVisible ? "shake" : null
                                  }`}
                                >
                                  click to watch
                                </p>
                              </>
                            )}
                          </TrackVisibility>
                        </div>
                        <div className="dsp-sm">
                          <p onClick={click}>click to watch</p>
                        </div>
                      </div>
                    </Slide>

                    <div className="block p18">
                      <Slide style={{ "--sal-delay": "0.2s" }}>
                        <div className="bonus-wrapper">
                          {parse(pageBlock[1].content.html)}

                          <div>
                            <img
                              src={pageImages[0].url}
                              alt="headspace"
                              className="headspace-img"
                            />
                          </div>
                        </div>
                      </Slide>
                    </div>

                    <Slide style={{ "--sal-delay": "0.2s" }}>
                      <section className="email-wrapper">
                        <form onSubmit={handleSubmit}>
                          <Container>
                            <div className="email-top p14">
                              {parse(pageBlock[4].content.html)}
                            </div>
                            <div className="email-parent">
                              <div className="input-wrapper">
                                <input
                                  type="text"
                                  name="SENDER EMAIL"
                                  className={`r-email ${
                                    senderEmailError === "1px solid #ff0055"
                                      ? shake === false
                                        ? null
                                        : "shake"
                                      : ""
                                  }`}
                                  value={senderEmail}
                                  style={{ border: senderEmailError }}
                                  placeholder="Enter sender’s email"
                                  onChange={e => setSenderEmail(e.target.value)}
                                />

                                <input
                                  type="text"
                                  name="RECIPIENT EMAIL"
                                  className={`r-email ${
                                    recipientEmailError === "1px solid #ff0055"
                                      ? shake === false
                                        ? null
                                        : "shake"
                                      : ""
                                  }`}
                                  value={recipientEmail}
                                  style={{ border: recipientEmailError }}
                                  placeholder="Enter recipient’s email"
                                  onChange={e =>
                                    setRecipientEmail(e.target.value)
                                  }
                                />
                              </div>

                              <Slide style={{ "--sal-delay": "0.2s" }}>
                                <div>
                                  <div
                                    className="email"
                                    style={{
                                      borderImageSource: `url(${pageImages[1].url})`,
                                    }}
                                  >
                                    <div>
                                      <Container
                                        div
                                        className={`ty ${
                                          thankyou === true ? "fadein" : ""
                                        }`}
                                      >
                                        {/* <img src={Tick} alt="tick" /> */}
                                        {thankyou === true ? (
                                          <div class="check-container">
                                            <div class="check-background">
                                              <svg
                                                viewBox="0 0 65 51"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M7 25L27.3077 44L58.5 7"
                                                  stroke="white"
                                                  stroke-width="13"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                              </svg>
                                            </div>
                                          </div>
                                        ) : null}

                                        <h2>Yay! Your email has been sent</h2>
                                        <p
                                          className="text-center"
                                          onClick={() => setThankyou(false)}
                                        >
                                          send it to more people!
                                        </p>
                                      </Container>
                                    </div>

                                    <div
                                      className={`mail ${
                                        thankyou === true ? "fadeout" : ""
                                      }`}
                                    >
                                      <div className="hey-ip">
                                        <h3>Hey</h3>
                                        <input
                                          type="text"
                                          name="RECIPIENT EMAIL"
                                          className={`r-name  ${
                                            rNameError === true
                                              ? shake === false
                                                ? null
                                                : "shake"
                                              : ""
                                          }`}
                                          value={recipientName}
                                          placeholder="Enter recipient’s name"
                                          onChange={e =>
                                            setRecipientName(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="p16">
                                        {parse(content.html)}
                                      </div>
                                      <h3>Regards,</h3>
                                      <input
                                        type="text"
                                        name="Name"
                                        className={`r-name name2  ${
                                          nameError === true
                                            ? shake === false
                                              ? null
                                              : "shake"
                                            : ""
                                        }`}
                                        value={name}
                                        placeholder="Enter your name"
                                        onChange={e => setName(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Slide>

                              <div
                                className={`send-btn ${
                                  thankyou === true ? "fadeout" : ""
                                }`}
                              >
                                <Buttons
                                  theme="primary w-100-sm"
                                  arrow
                                  text="SEND EMAIL"
                                />
                              </div>
                            </div>
                            <div className="dice">
                              <img src={Dice} alt="img" />
                            </div>
                          </Container>
                        </form>
                      </section>
                    </Slide>

                    <section className="pink-box">
                      <Slide style={{ "--sal-delay": "0.2s" }}>
                        <Container>
                          <div className="health-cta">
                            {parse(pageBlock[3].content.html)}
                          </div>
                          <div>
                            <Buttons
                              theme="primary"
                              arrow
                              text="JOIN THE COMMUNITY"
                            />
                          </div>
                        </Container>
                      </Slide>
                    </section>

                    <Slide style={{ "--sal-delay": "0.1s" }}>
                      <div className="mental-health-ftr">
                        <Container>
                          <Row>
                            <Col lg={4} sm={12} className="ftr-left">
                              <div className="p16">
                                <a className="a-logo" href="/">
                                  <img src={pageImages[2].url} alt="img" />
                                </a>
                              </div>
                            </Col>
                            <Col lg={4} sm={12} className="ftr-middle">
                              <div className="mx-auto tag p16 text-center">
                                <p>#mspmentalhealth</p>
                              </div>
                            </Col>
                            <Col lg={4} sm={12} className="ftr-right">
                              <div className="footer-links icon-fill d-flex mx-auto justify-content-center align-items-center">
                                <div>Share:</div>
                                <Links
                                  items={data.SuperOps.links[0]}
                                  iconOnly
                                />
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Slide>
                  </div>
                </Frame>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default MentalHealthCopy
